import React from 'react'

import Layout from '../components/Layout'

const HotelPageTemplate = () => (
    <Layout>
       
    </Layout>
)

export default HotelPageTemplate